import { computed } from 'vue';
import { mapActions, mapState } from 'vuex';
import NoSleep from 'nosleep.js';
import Manifest from '~manifest';
import format from 'date-fns/format';
import { isNil } from 'lodash';
import { startSSE, endSSE } from '~/utils/eventSource';
import API from '~/api-tc';
import singletonSocket from '~/utils/socket';
import nativeIosAppMixins from '~/mixins/native-ios-app';
import common from '~/utils/common';
import { getMobile, isSafari } from '../../utils';

const noSleep = new NoSleep();
const ua = window.navigator.userAgent;
const iOS = !!ua.match(/iPad/i) || !!ua.match(/iPhone/i);
const webkit = !!ua.match(/WebKit/i);
const iOSSafari = iOS && webkit && !ua.match(/CriOS/i);

export default {
  inject: ['moment'],
  provide() {
    return {
      getVersion: () => {
        this.getVersion();
      },
      BGMPlay: () => {
        this.$refs.Mp3Player && this.$refs.Mp3Player.play();
      },
      BGMPause: () => {
        this.$refs.Mp3Player && this.$refs.Mp3Player.pause();
      },
      checkUserNotificationIsEnabled: () => {
        this.checkUserNotificationIsEnabled();
      },
      playSound: this.playSound,
      isYoutubePlayerShow: computed(() => this.isYoutubePlayerShow),
      isRotate: computed(() => this.isRotate),
      isSafari: isSafari(),
    };
  },
  mixins: [nativeIosAppMixins],
  data() {
    return {
      logo: require(`@/assets/images/${process.env.VUE_APP_KEYWORD}/logo.webp`),
      appName: process.env.VUE_APP_TITLE,
      isDomainLoaded: false,
      prompt: false,
      isRotate: false,
      loadedCount: 0,
      deferredPrompt: null,
      isSafariHintShow: false,
      isSafari: iOSSafari,
      isYoutubePlayerShow: true,
      isYoutubePlayerLoaded: false,
      isIosTutorialShow: false,
      isOnesignalInit: false,
      defaultViewport: {
        width: 0,
        height: 0,
      },
      currentViewport: {
        width: 0,
        height: 0,
      },
      WebSocket: {
        HeartsInterval: null,
      },
      isGotVersion: false,
      isQueryChecked: false,
      isSubscribeLoading: false,
      isInitDataLoaded: false,
      timer: null,
      hintTimer: null,
      hintCountDown: 4,
      isContentLoaded: false,
      barWidth: 0,
      currentProgress: 0,
      progressInterval: null,
      step: 0.1,
      rightNowPage: false, // document.visibilityState === 'visible'
      socketInstance: null,
    };
  },
  watch: {
    $route(to, from) {
      this.windowResize();
      if (to.name === 'lobbyIndex') {
        this.$store.dispatch('chat/actionPopBonusNotify', Infinity);
      }
    },
    language: {
      immediate: true,
      handler(val) {
        const classList = document.querySelector('html').classList;
        classList.remove('th');
        classList.remove('en');
        classList.remove('zh-CN');
        classList.add(val);
      },
    },
    token: {
      immediate: true,
      async handler(val, oldVal) {
        if (val) {
          if (!this.socketInstance) {
            // socket 尚未初始化
            this.socketInstance = singletonSocket();
          } else {
            // socket 已初始化，需更新 socket token 並重連
            this.socketInstance.connectSocketWithNewToken();
          }
        } else if (!val && this.socketInstance?.socket?.connect) {
          this.socketInstance.closeSocket();
        }

        // this.fetchData();

        if (val && val !== oldVal) {
          // connect server side event
          this.setSSE();
        } else {
          // disconnect server side event
          endSSE();
        }

        if (val && isNil(oldVal)) {
          this.$store.dispatch('app/fetchLobbyInfo');
          this.$store.dispatch('chat/actionGetBonusNotify');
        }
      },
    },
    userInfo: {
      immediate: true,
      handler(newVal, oldVal) {
        if (newVal.Account === oldVal?.Account && newVal.FBID === oldVal?.FBID) return;
        if (newVal.Account || newVal.FBID) {
          window.dataLayer?.push({ ga_c_id: newVal.Account || newVal.FBID });
        }
      },
    },
    onesignalData: {
      handler(val) {
        if (!val?.APPID || this.isOnesignalInit) return;
        this.getOneSignal();
      },
      immediate: true,
      deep: true,
    },
    maintainInfo: {
      handler(val) {
        if (val?.status && val?.startTime && val?.endTime) {
          const { startTime, endTime } = val;
          const now = this.moment();
          const isAfter = now.isAfter(this.moment(startTime));
          const isBefore = now.isBefore(this.moment(endTime));
          if (isAfter && isBefore) this.$router.push('/');
        }
      },
      immediate: true,
      deep: true,
    },
    isRotateShow: {
      handler(v) {
        setTimeout(() => {
          if (v) {
            Object.assign(document.body.style, {
              width: `${this.currentViewport.height}px`,
              height: `${this.currentViewport.width}px`,
              transform: 'rotate(90deg) translateY(-100%)',
              transformOrigin: 'top left',
            });
          } else {
            document.body.style = {};
          }
          this.windowResize();
        }, 550);
      },
      immediate: true,
    },
  },
  computed: {
    ...mapState('app', ['drawer']),
    language() {
      return this.$store.getters.language;
    },
    token() {
      return this.$store.state.member.token;
    },
    isRotateShow() {
      return this.$store.getters.isOpenGameWallet
        ? this.isRotate
        : this.isRotate && !this.$store.getters.isGameView;
    },
    userInfo() {
      return this.$store.getters.MemberInfo;
    },
    localTime() {
      const BaseTime = this.moment.utc('2021-01-01 00:01'); // UTC+0時間基準點 日期隨便設定 主要是後面的時間
      const localTime = this.moment(BaseTime).local().format('HH:mm'); // 轉成當地時間

      return localTime;
    },
    onesignalData() {
      return this.$store.getters['initData/getSystemConfig']('APP_PUSH_CONFIG');
    },
    logoImgUrl() {
      return this.$store.state.initData.appLogoPicture[0]?.imgUrl || this.logo;
    },
    isPromptShow: {
      get() {
        return this.drawer.show;
      },
      set(value) {
        this.setDrawer({ show: value, from: 'install' });
      },
    },
    maintainInfo() {
      return this.$store.state.maintainInfo;
    },
    isMaintain() {
      return this.maintainInfo.status || this.maintainInfo.forceMaintain;
    },
  },
  beforeCreate() {
    document.onreadystatechange = () => {
      if (document.readyState === 'complete') {
        this.barWidth = 100;
        clearInterval(this.progressInterval);
        setTimeout(() => {
          this.isContentLoaded = true;
        }, 1000);
      }
    };
  },
  async created() {
    this.$store.dispatch('actionGetMaintainInfo');
    document.addEventListener('visibilitychange', () => {
      // SSE
      if (document.visibilityState === 'visible') {
        if (this.token) {
          this.$store.dispatch('app/fetchLobbyInfo');
        }
        this.rightNowPage = true;
      } else {
        this.rightNowPage = false;
      }
    });

    await this.$store.dispatch('app/setIsAlertShow', false);
    const qs = new URLSearchParams(window.location.search);
    const mid = qs.get('mid');
    if (mid && !this.$store.state.member.token) {
      this.$router.push({
        name: 'lobbyIndex',
        params: {
          action: 'signup',
          mid,
        },
      });
    }
    // const MaintainState = await this.checkMaintain();
    // if (MaintainState) {
    //   this.isGotVersion = await this.getVersion();
    // }
    await this.checkQuery();
    await this.createManifest();
    await this.$store.dispatch('app/setGameView', false);
    this.windowResize();
    window.addEventListener('load', this.windowLoading);
    window.addEventListener('resize', this.windowResize);
    if (window.visualViewport) {
      window.visualViewport.addEventListener('resize', this.windowResize);
    }
    window.addEventListener('orientationchange', this.windowResize);
    window.addEventListener('gesturestart', (event) => {
      event.preventDefault();
    });
    this.enableNoSleep();
    window.addEventListener('blur', () => {
      this.$nextTick(() => {
        this.$refs.Mp3Player && this.$refs.Mp3Player.pause();
      });
    });
    window.addEventListener('focus', () => {
      this.windowResize();
      if (
        this.$store.state.app.isBGMEnable
				&& !this.$store.getters.isGameView
      ) {
        this.$nextTick(() => {
          this.$refs.Mp3Player && this.$refs.Mp3Player.play();
        });
      }
    });
    window.addEventListener('beforeinstallprompt', (e) => {
      e.preventDefault();
      this.deferredPrompt = e;
      if (e.prompt) {
        document
          .querySelector('#app')
          .addEventListener('click', this.handleTriggerPrompt);
      }
    });
    window.addEventListener('appinstalled', () => {
      this.deferredPrompt = null;
      this.isPromptShow = false;
    });
    window.OneSignal = window.OneSignal ?? null;
    window.createjs.Sound.registerSound(
      require('@/assets/music/open.wav'),
      'open',
    );
    window.createjs.Sound.registerSound(
      require('@/assets/music/close.wav'),
      'close',
    );
    window.createjs.Sound.registerSound(
      require('@/assets/music/take-reward.mp3'),
      'takeReward',
    );

    this.$store.dispatch('mailBox/actionGetNews', {
      total: 0,
      currentPage: 1,
      pageSize: 10,
      sorts: [
        {
          columnName: 'addTime',
          orderType: 'desc',
        },
      ],
    });
    this.initApplePayEvent();
  },
  beforeDestroy() {
    window.removeEventListener('load', this.windowLoading);
    window.removeEventListener('resize', this.windowResize);
    window.removeEventListener('orientationchange', this.windowResize);
    if (this.timer) {
      clearInterval(this.timer);
    }
    if (this.hintTimer) {
      clearInterval(this.hintTimer);
    }
    endSSE();
  },
  async mounted() {
    this.$store.dispatch('promotion/actionInitCountDownTimer', 0);
    this.$store.dispatch('actionSetActiveCustomerService', null);
    this.$store.commit('financial/setIsOnlinePaymentTriggered', false);
    await this.$store.dispatch('initData/getInitData');
    this.$store.dispatch('game/actionInitSelectedGameTag', { brand: undefined, name: 'all', id: -1 });
    this.$store.dispatch('game/actionFilterTagGames', {});
    this.$store.dispatch('ranking/getDailyReport', {
      actionType: 'moneyRank',
      searchDate: format(new Date(), 'yyyy/MM/dd'),
    });
    this.$store.dispatch('ranking/getDailyReport', {
      actionType: 'creditRank',
      searchDate: format(new Date(), 'yyyy/MM/dd'),
    });
    this.$store.dispatch('app/setIsOpenGameWallet', false);
    this.isInitDataLoaded = true;
    this.progressInterval = setInterval(() => {
      this.currentProgress += this.step;
      const progress = Math.round(
        (Math.atan(this.currentProgress) / (Math.PI / 2)) * 100 * 1000,
      ) / 1000;
      this.barWidth = progress;
      if (progress >= 100) {
        clearInterval(this.progressInterval);
        this.isContentLoaded = true;
      } else if (progress >= 70) {
        this.step = 0.01;
      }
    }, 1000);

    setTimeout(() => {
      if (!this.isContentLoaded) {
        this.barWidth = 100;
        clearInterval(this.progressInterval);
        this.isContentLoaded = true;
      }
    }, 20000);
    this.$store.commit('app/SET_ISLOADING', false);
    this.$store.dispatch('app/setGameFilter', {
      gameType: null,
      provider: 'top50',
      providerId: null,
    });
    if (this.isSafari && !window.navigator.standalone) {
      this.isSafariHintShow = true;
      this.$nextTick(() => {
        if (this.$refs.IOSVideo) {
          this.$refs.IOSVideo.onloadeddata = () => {
            this.isYoutubePlayerLoaded = true;
          };
        }
      });
    } else {
      this.isYoutubePlayerShow = false;
    }
    // this.checkCrossDay();
    this.startHintTimer();
    window.addEventListener('message', (e) => {
      if (e.origin === window.origin && this.$route.path === '/gameview' && e.data?.event === 'close') {
        if (this.token) {
          this.$router.push('/slots');
        } else {
          this.$router.push('/');
        }
      }
    });
  },
  methods: {
    ...mapActions('app', ['setDrawer']),
    async getVersion() {
      // await API.Version.Check({}).then(res => {
      //   const { Data } = res
      //   console.log('當前版本:', Data.Version)
      //   this.$store.dispatch('app/setVersion', Data.Version)
      // })
      return Promise.resolve(true);
    },
    windowLoading(e) {
      this.windowResize(e);
    },
    async windowResize(e) {
      // 單純旋轉螢幕紀錄原始寬高
      if (
        this.defaultViewport.width === 0
        || window.innerWidth + window.innerHeight
          === this.defaultViewport.width + this.defaultViewport.height
      ) {
        this.defaultViewport.width = window.innerWidth;
        this.defaultViewport.height = window.innerHeight;
      }
      if (window.innerWidth < window.innerHeight) {
        this.isRotate = true;
      } else {
        this.isRotate = false;
        this.$store.dispatch('actionSetShowRotateModal', false);
      }

      // 為了 Android 鍵盤高度會擠壓 innerheight
      if (
        this.defaultViewport.width === window.innerWidth
        && this.defaultViewport.height !== window.innerHeight
      ) return;

      this.currentViewport = { // 記錄當前視窗寬高
        width: window.innerWidth,
        height: window.innerHeight,
      };
      setTimeout(() => {
        const windowsVH = this.isRotateShow ? window.innerWidth / 100 : window.innerHeight / 100;
        const windowsVW = this.isRotateShow ? window.innerHeight / 100 : window.innerWidth / 100;
        document.documentElement.style.setProperty('--vh', `${windowsVH}px`);
        document.documentElement.style.setProperty('--vw', `${windowsVW}px`);
      }, 500);
    },
    faceBookLogout() {
      if (!window.FB) { return; }
      // 檢查登入狀態
      window.FB.getLoginStatus((response) => {
        // 檢查登入狀態
        if (response.status === 'connected') {
          // 移除授權
          // window.FB.api('/me/permissions', 'DELETE', function(res) {
          // 用戶登出
          window.FB.logout((response) => {
            console.log(response);
          });
          // })
        } else {
          // do something
          console.log('logout connected else something');
        }
      });
    },
    getOneSignal() {
      if (!window.OneSignal) { return; }
      let self = this;
      window.OneSignal.push(() => {
        /* These examples are all valid */
        let isPushSupported = window.OneSignal?.isPushNotificationsSupported();
        console.log('isPushNotify supported:', isPushSupported);
        if (isPushSupported) {
          // Push notifications are supported
          // 瀏覽器支援 初始化OneSignal
          // 測試要注意 一定要用手機開 瀏覽器手機模式沒用
          console.log('Push notifications are supported');
          // let device = getMobile();
          // if (device === 'iPhone' || device === 'iPad' || device === 'iPod') return;
          window.OneSignal?.init({
            appId: this.onesignalData.APPID,
            autoResubscribe: true,
            notifyButton: {
              enable: false,
            },
          });
          this.isOnesignalInit = true;
        }
      });
      this.checkUserNotificationIsEnabled();
      window.OneSignal?.on('subscriptionChange', (isSubscribed) => {
        console.log("The user's subscription state is now:", isSubscribed);
        if (isSubscribed) {
          // console.log('用戶允許')
          window.OneSignal?.getUserId().then((res) => {
            console.log('取得推播ID', res);
            // 防止1秒內的連打
            if (self.isSubscribeLoading) {
              console.log('訂閱裝置碼讀取中, 已擋下');
              return;
            }
            self.isSubscribeLoading = true;
            const formData = {
              machineNo: res,
            };
            console.log('儲存推播裝置碼 FormData===>', formData);
            const { subscribleNotify, subscribeBeforeLogin } = API.member;
            const req = self.token ? subscribleNotify : subscribeBeforeLogin;
            req(formData)
              .then((response) => {
                console.log(response);
              })
              .finally(() => {
                self.isSubscribeLoading = false;
              });
          });
        } else {
          // 否則
          console.log('用戶拒絕');
        }
      });
    },
    checkUserNotificationIsEnabled() {
      let self = this;
      window.OneSignal?.isPushNotificationsEnabled().then((isEnabled) => {
        if (isEnabled) {
          console.log('Push notifications are enabled!');
          window.OneSignal?.push([
            'getNotificationPermission',
            function (permission) {
              // console.log('Site Notification Permission:', permission)
              // (Output) Site Notification Permission: default
              switch (permission) {
                case 'granted': // 用戶本來就同意推播
                  window.OneSignal?.getUserId().then((res) => {
                    console.log('取得推播ID', res);
                    if (self.isSubscribeLoading) {
                      console.log('訂閱裝置碼讀取中, 已擋下');
                      return;
                    }
                    self.isSubscribeLoading = true;
                    const formData = {
                      machineNo: res,
                    };
                    const { subscribleNotify, subscribeBeforeLogin } = API.member;
                    const req = self.token ? subscribleNotify : subscribeBeforeLogin;
                    req(formData)
                      .then((response) => {
                        console.log(response);
                      })
                      .finally(() => {
                        self.isSubscribeLoading = false;
                      });
                  });
                  break;
                case 'denied': // 用戶本身就拒絕了
                  window.OneSignal?.showSlidedownPrompt();
                  break;
                default: // 用戶設定是預設詢問
                  window.OneSignal?.showSlidedownPrompt();
                  break;
              }
            },
          ]);
        } else {
          console.log('Push notifications are not enabled yet.');
          window.OneSignal?.showSlidedownPrompt();
        }
      });
    },
    handleLoaded() {
      this.$nextTick(() => {
        this.$store.commit('app/SET_ISLOADING', false);
      });
    },
    checkQuery() {
      // query 有 sectionSerial 則檢查正確並儲存, 如果是推薦人連結不做換頁
      const qs = new URLSearchParams(window.location.search);
      const platform = qs.get('platform');
      if (platform) this.$store.dispatch('actionSetPlatform', platform);

      const sectionSerial = qs.get('sectionSerial');
      if (sectionSerial) {
        this.$store.dispatch('member/setSectionSerial', sectionSerial);
      }
      this.isQueryChecked = true;
    },
    enableNoSleep() {
      document.addEventListener(
        'click',
        function enableNoSleep() {
          noSleep.enable();
          document.removeEventListener('click', enableNoSleep, false);
        },
        false,
      );
      document.addEventListener(
        'touchstart',
        function enableNoSleep() {
          noSleep.enable();
          document.removeEventListener('touchstart', enableNoSleep, false);
        },
        false,
      );
    },
    async dismiss() {
      this.deferredPrompt = null;
      this.isPromptShow = false;
    },
    async handleDrawerBtnClick() {
      switch (this.drawer.from) {
        case '401':
          this.setDrawer({ show: false, from: 'install' });
          break;
        case 'install':
          this.$track('點擊Android安裝PWA按鈕', {
            event_category: 'click',
            event_label: '點擊Android安裝PWA按鈕',
          });
          this.deferredPrompt.prompt();
      }
    },
    handleDrawerClose() {
      this.dismiss();
    },
    handleSafariDrawerClose() {
      this.isSafariHintShow = false;
    },
    handleTriggerPrompt(e) {
      if (this.deferredPrompt && !this.isSafari) {
        this.isPromptShow = true;
        document
          .querySelector('#app')
          .removeEventListener('click', this.handleTriggerPrompt);
      }
    },
    async checkMaintain() {
      // const res = await API.System.CheckMaintain()
      // if (res.ErrorCode === 0) {
      //   this.$store.dispatch('app/setIsMaintainence', false)
      // } else if (res.ErrorCode === 999999) {
      //   await this.$store.dispatch('user/setAgentCode', '')
      // }
      // return Promise.resolve(res.ErrorCode === 0 || res.ErrorCode === 900012)
      return Promise.resolve(true);
    },
    fetchData() {
      // if (!this.isQueryChecked) { // 等待query檢查完才執行
      //   setTimeout(() => {
      //     this.fetchData()
      //   }, 500)
      //   return
      // }
      // const { dispatch } = this.$store
      // dispatch('user/resetLoading')
      // dispatch('wallet/resetLoading')
      // dispatch('dailyMission/resetLoading')
      // dispatch('promotion/resetLoading')
      // dispatch('systemMail/resetLoading')
      // if (this.token) {
      //   dispatch('user/getAllData')
      //   dispatch('wallet/getAllData')
      //   dispatch('dailyMission/getAllData')
      //   dispatch('promotion/getAllData')
      //   dispatch('systemMail/getAllData')
      //   /* 預設五分鐘輪詢 */
      //   // this.timer = setInterval(() => {
      //   //   dispatch('user/getAllData')
      //   //   dispatch('wallet/getAllData')
      //   //   dispatch('dailyMission/getAllData')
      //   //   dispatch('promotion/getAllData')
      //   //   dispatch('systemMail/getAllData')
      //   // }, 1000 * 60 * 5)
      // } else {
      //   if (this.timer) clearInterval(this.timer)
      // }
    },
    createManifest() {
      const sectionSerial = this.$store.state.member.sectionSerial;
      const pid = common.getLocalStorageValue('pid');
      const Url = new URL(window.location.origin);
      if (sectionSerial) Url.appendParams.append('sectionSerial', sectionSerial);
      if (pid) Url.searchParams.append('pid', pid);
      const manifest = {
        ...Manifest,
        start_url: Url.href,
        scope: window.location.origin,
        icons: [
          ...Manifest.icons.map((item) => ({
            ...item,
            src: `${window.location.origin}${item.src}`,
          })),
        ],
      };
      const stringManifest = JSON.stringify(manifest);
      const blob = new Blob([stringManifest], { type: 'application/json' });
      const manifestURL = URL.createObjectURL(blob);
      const manifestNode = document.createElement('link');
      manifestNode.rel = 'manifest';
      manifestNode.href = manifestURL;
      document.head.appendChild(manifestNode);
    },
    checkCrossDay() {
      // 30秒檢查一次時間 檢查到時間=UTC+0 00:01則重打每日任務觸發掛任務
      this.timer = setInterval(() => {
        const currentTime = this.moment().local().format('HH:mm'); // 當前當地時間
        if (currentTime === this.localTime) {
          this.$store.dispatch('dailyMission/getAllData');
        }
      }, 1000 * 30);
    },
    startHintTimer() {
      this.hintTimer = setInterval(() => {
        if (!this.isYoutubePlayerShow) {
          this.hintCountDown -= 1;
        }
        if (this.hintCountDown <= 0) {
          clearInterval(this.hintTimer);
        }
      }, 1000);
    },
    handleTutorialClose() {
      this.isIosTutorialShow = false;
      this.isSafariHintShow = false;
    },
    restartSSE() {
      if (this.rightNowPage) {
        // eslint-disable-next-line no-use-before-define
        setTimeout(this.setSSE, 60000);
      } else {
        setTimeout(this.restartSSE, 60000);
      }
    },
    setSSE() {
      console.log('setSSE');
      // const eventSource = startSSE();

      // eventSource.addEventListener('websiteMail.newMessage', (event) => {
      //   console.log('[EVENT_SOURCE] - websiteMail.newMessage: ');
      // const data = JSON.parse(event.data);
      // if (Array.isArray(data) && data.length > 0) {
      //   // BA-1887 for 昀諺fb pixel分析存款用
      //   const depositSum = data.reduce((sum, msg) => {
      //     if (isNumber(msg.options && msg.options.deposit)) {
      //       sum += msg.options.deposit;
      //     }
      //     const data = {
      //       deposit: Number(msg.options.deposit),
      //       depositTimes: Number(msg.options.depositTimes),
      //       // eventCreatedAt: Number(msg.options.eventCreatedAt),
      //       memberId: this.$store.state.member.memberInfo.memberId,
      //     };

      //     // send deposit success message
      //     window.postMessage(
      //       {
      //         type: `scope/${CONSTANT_EVENT.type.DEPOSIT_SUCCESS}`,
      //         data,
      //       },
      //       window.location.origin,
      //     );
      //     if (msg.options.depositTimes === FIRST_DEPOSIT) {
      //       window.postMessage(
      //         {
      //           type: `scope/${CONSTANT_EVENT.type.FIRST_DEPOSIT_SUCCESS}`,
      //           data,
      //         },
      //         window.location.origin,
      //       );
      //     }
      //     this.$message.success({
      //       title: this.$t('common.title'),
      //       message: template(this.$t('member.gameDeposit.deposit.success'))({
      //         brand: this.$t('vue.common.cashAmount'),
      //         symbol: this.$t(
      //           `common.currencySymbol.${this.$store.getters[
      //             'initData/getSystemConfig'
      //           ]('system.config.currency.symbol')}`,
      //         ),
      //         amount: msg.options.deposit,
      //       }),
      //     });

      //     return sum;
      //   }, 0);

      //   if (depositSum > 0) {
      //     this.$store
      //       .dispatch('financial/actionQueryAllPlatMoney', null, {
      //         root: true,
      //       })
      //       .then(() => {
      //         console.info('dispatch financial/actionQueryAllPlatMoney');
      //       })
      //       .catch((e) => console.error(e));
      //   }

      //   // window.location = `/?deposit=${depositSum}`;
      // }
      // });

      // eventSource.onerror = () => {
      //   if (eventSource.readyState === 2) {
      //     this.restartSSE();
      //   }
      // };
    },
    playSound(type = 'open') {
      if (!this.$store.state.app.isBGMEnable) return;
      window.createjs?.Sound?.play(type);
    },
    handleCloseSafariHint() {
      this.playSound('close');
      this.isSafariHintShow = false;
    }
  },
};
